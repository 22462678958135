import React from 'react';
import FullCircle from '../../basic/progress/FullCircle';
import HalfCircle from '../../basic/progress/HalfCircle';
import ChartForArea from '../cards/chart-cards/area/ChartForArea';
import DeviceCardContainer from '../cards/device-log-card/DeviceLogCard';
import { getFormattedTime } from '../../../utils/utils';
import { daySummary } from '../../../pages/home/components/summaries/data';
import './displaySummary.css';

export default function DisplaySummary({ item = {} }) {
  return (
    <div className='display-summary'>
      <div className='grid grid-flow-row grid-cols-12 gap-4'>
        <div className='grid-item '>
          <DeviceCardContainer>
            <div className='content '>
              <h2 className=''>Current Run-Time</h2>
              <div className='w-[116px] h-11   absolute left-1/2 top-1/2 transform -translate-x-1/2  -translate-y-1/2 centerXY rounded-xl border-4 border-primary-100 dark:border-primary-700'>
                <p className='text-xl'>{item.duration ? getFormattedTime(item.duration) : '00:00:00'}</p>
              </div>
            </div>
          </DeviceCardContainer>
        </div>

        <div className='grid-item'>
          <DeviceCardContainer>
            <div className='content !p-0 absolute left-0 bottom-0 w-full'>
              {/* <h4 className=''>Monthly to Date</h4> */}
              <div className='flex justify-between items-center px-4 pt-2'>
                <h2 className=''>kWh</h2>
                <h1 className=' text-xl'>{item.energy_consumed || '00'}</h1>
              </div>
              <ChartForArea options={{ data: daySummary, fill: '#a092b0' }} className='!rounded-none'>
                <div className='content px-4 py-2'>{/* <p>{data.runningDevices ? data.runningDevices : '0'}</p> */}</div>
              </ChartForArea>

              {/* <h4 className=' w-full absolute left-0 bottom-5 text-center'>Details</h4> */}
            </div>
          </DeviceCardContainer>
        </div>
        <div className='grid-item'>
          <DeviceCardContainer>
            <div className='content'>
              <h2>Current</h2>
              <HalfCircle percentage={item.current || 0} />
              <div className='w-[116px] h-11 dark:text-white text-primary-700  absolute left-1/2 top-1/2 transform -translate-x-1/2  -translate-y-1/2 centerXY flex-col'>
                <p className='text-xl'> {item.current || '0.0'} </p>
                <h5 className=''>A</h5>{' '}
              </div>

              <h4 className='w-full absolute left-0 bottom-5 text-center'>Details</h4>
            </div>
          </DeviceCardContainer>
        </div>

        <div className=' grid-item'>
          <DeviceCardContainer>
            <div className='content'>
              {/* <h4 className=''>Monthly to Date</h4> */}
              <div className=''>
                <h2>Current Voltage</h2>
                <div className='flex justify-center'>
                  <FullCircle percentage={item.voltage?.toFixed(0) || 0}>
                    <p className='absolute text-lg left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 '>{item.voltage?.toFixed(0) || '0.0'} V </p>
                  </FullCircle>
                </div>
              </div>

              <h4 className=' w-full absolute left-0 bottom-5 text-center'>Details</h4>
            </div>
          </DeviceCardContainer>
        </div>
      </div>
    </div>
  );
}

export const options = [
  { id: 1, title: '19-01-23', value: '' },

  { id: 2, title: '20-01-23', value: 60 * 60 },
  { id: 3, title: '21-01-23', value: 6 * 60 * 60 },
  { id: 4, title: '22-01-23', value: 24 * 60 * 60 },
  { id: 5, title: '13-01-23', value: 7 * 24 * 60 * 60 },
];
