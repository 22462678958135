import { useEffect, useRef, useState } from 'react';
import { isMatchWith } from '../../../utils/utils';
import './Selected.css';

export default function SelectFromOption({
  placeholder = '',
  options = [],
  onClick = () => {},
  displayKey = 'name',
  keyValue = 'id',
  headerClass = 'header-class',
  contentClass = 'content-class',
  readOnly = true,
  fieldError = '',
  disableOption = false,
  value = {},
}) {
  const [tempOptions, updateTempOptions] = useState([]);
  const [listShow, setListShow] = useState(false);
  const [tempValue, updateTempValue] = useState('');
  const divRef = useRef();

  useEffect(() => {
    updateTempValue(value[displayKey] || '');
  }, [value, displayKey]);

  useEffect(() => {
    updateTempOptions(options);
  }, [options]);

  const searchInput = (value) => {
    updateTempValue(value);
    const tempList = options.filter((op) => isMatchWith(op[displayKey], value) !== null);
    updateTempOptions(tempList);
  };

  const updateInputValue = (op) => {
    onClick(op);
    setListShow(false);
  };

  const handleListener = (e) => {
    if (!divRef.current.contains(e.target)) {
      setListShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleListener);
    return () => window.removeEventListener('click', handleListener);
  }, []);

  return (
    <div ref={divRef} className='selectFromInput'>
      <div onClick={() => !disableOption && setListShow(true)} className={`px-1 flex items-center cursor-pointer ${headerClass}`}>
        <input
          readOnly={readOnly}
          placeholder={placeholder}
          onChange={(e) => searchInput(e.target.value)}
          className='px-1 w-full outline-none cursor-pointer'
          type='text'
          value={tempValue}
        />
        <i style={{ fontSize: '14px', marginRight: '6px' }} className='fa fa-caret-down '></i>
      </div>
      {fieldError && <label className='text-[#dd5a5a] text-sm'>{fieldError}</label>}

      <div className={`listContainer ${contentClass} ${listShow ? 'block' : 'hidden'}`}>
        {tempOptions?.map((op) => (
          <li
            key={op[keyValue]}
            onClick={() => updateInputValue(op)}
            className='list-none w-full cursor-pointer px-2 my-1 py-1 bg-first-50 hover:bg-first-100'
          >
            {op[displayKey]}
          </li>
        ))}
      </div>
    </div>
  );
}

// options must be as like as
// const options = [
//     { id: 1, title: "English", value: "english" },
//     { id: 2, title: "Bangla", value: "bangla" },
//     { id: 3, title: "Arabic", value: "arabic" },
//   ];

//onClick e listContainer close && setValue
