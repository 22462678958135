import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOMER_ADMIN, getCloseTowerSites, getCompanies } from '../../apis/apis';
import Button from '../../components/basic/button';
import Input from '../../components/basic/inputs/Input/Input';
import SelectFromOption from '../../components/basic/SelectedInput/SelectedInput';
import { makeSubmittableData } from '../../components/common/forms/makeSubmittableData';
import { validation } from '../../components/common/forms/validator';
import './style.css';

export default function CloseTowerSites() {
  const {
    user: {
      data: { id, user_type, company },
    },
  } = useSelector((state) => state.auth) || {};

  const initFormData = {
    latitude: { value: '', required: true },
    longitude: { value: '', required: true },
    company_id: { value: '' },
  };

  const [formData, updateFormData] = useState(initFormData);
  const [selCompany, setSelCompany] = useState({});
  const companyList = useSelector((state) => state.companies);
  const [closeTowers, updateCloseTowers] = useState([]);
  const [seeMore, setSeeMore] = useState(false);
  const [tableData, updateTableData] = useState([]);

  const handleChange = (fieldName, data) => {
    updateFormData({ ...formData, [fieldName]: data });
  };

  const handleSubmit = async () => {
    let data = makeSubmittableData({
      ...formData,
    });
    if (data.status) {
      const res = await getCloseTowerSites(data.data);
      if (res.status === true || 200) {
        updateCloseTowers(res.data);
      }
    } else {
      updateFormData({ ...data.data });
    }
  };

  useEffect(() => {
    let copy = { ...formData };
    copy['company_id'].value = selCompany.id;
    copy['company_id'].error = false;
    updateFormData({ ...copy });
  }, [selCompany]);

  useEffect(() => {
    getCompanies();
    if (user_type === CUSTOMER_ADMIN) {
      setSelCompany(company);
    }
  }, []);

  useEffect(() => {
    if (seeMore) {
      updateTableData(closeTowers);
    } else {
      updateTableData(closeTowers?.slice(0, 1));
    }
  }, [seeMore, closeTowers]);

  return (
    <div>
      <div className='max-w-md mx-auto'>
        <div className='mb-5'>
          <Input
            name='latitude'
            value={formData.latitude?.value || ''}
            onChange={handleChange}
            validation={validation.number}
            placeholder='Enter Latitude'
            className='custom-input'
            type='text'
            fieldError={formData.latitude?.error || false}
          />
        </div>
        <div className='mb-5'>
          <Input
            name='longitude'
            value={formData.longitude?.value || ''}
            onChange={handleChange}
            validation={validation.number}
            placeholder='Enter Longitude'
            className='custom-input'
            type='text'
            fieldError={formData.longitude?.error || false}
          />
        </div>

        <div className='mb-5'>
          <SelectFromOption
            onClick={(op) => setSelCompany(op)}
            value={selCompany}
            options={companyList.list || []}
            headerClass='header-class'
            placeholder='Select Company'
            fieldError={formData.company_id?.error || false}
            disableOption={user_type === CUSTOMER_ADMIN || false}
          />
        </div>
        <div className='mt-5 ml-auto w-[160px]'>
          <Button onClick={handleSubmit} className='btn-normal btnClass p-2' title='Get tower sites ' />
        </div>
      </div>
      {tableData.length > 0 && (
        <div className='my-10'>
          <div className='table-container p-4 rounded-lg border border-[#8080801f] text-sm'>
            <div className='grid grid-cols-12 grid-flow-row gap-4'>
              {tableData?.map((it, i) => (
                <div className='col-span-12 sm:col-span-6 xl:col-span-4  2xl:col-span-3'>
                  <CloseTowerCard item={it} key={i} />
                </div>
              ))}
            </div>
          </div>
          <div className='mt-2 ml-auto w-[160px]'>
            <Button onClick={() => setSeeMore(!seeMore)} className='btn-normal btnClass p-2' title={seeMore ? 'See less' : 'See more'} />
          </div>
        </div>
      )}
    </div>
  );
}

function CloseTowerCard({ item }) {
  return (
    <div className='close-tower-card '>
      <div className='flex items-center justify-between'>
        <p>
          Code: <span>{item.code || ''}</span>
        </p>
        <p>
          Distance: <span>{item.distance?.toFixed(4) || 0} km </span>
        </p>
      </div>
      <div>
        <p>
          Latitude: <span>{item.latitude || '-'}</span>
        </p>
        <p>
          Longitude: <span>{item.longitude || '-'}</span>
        </p>
        <p>Address:</p>
        <div className='ml-14'>
          <p>
            Name: <span>{item.name || '-'}</span>
          </p>
          <p>
            Address: <span>{item.address || '-'}</span>
          </p>
          <p>
            Thana: <span>{item.thana || '-'}</span>
          </p>
          <p>
            Region: <span>{item.region || '-'}</span>
          </p>
        </div>
        <div></div>
      </div>
    </div>
  );
}
