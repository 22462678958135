import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CUSTOMER_ADMIN, downloadDeviceLogs, getActiveDeviceLogs, refreshTime, SUPER_ADMIN } from '../../../apis/apis';
import Button from '../../../components/basic/button';
import Modal from '../../../components/basic/Modals/modal with create Portal/Modal';
import SelectFromOption from '../../../components/basic/SelectedInput/SelectedInput';
import DateRangePicker from '../../../components/common/date-picker/DateRangePicker';
import NoData from '../../../components/common/no-data/NoData';
import { PaginatedItems } from '../../../components/common/pagination/Paginate';
import Suspender from '../../../components/common/suspender/Suspender';
import { parseDateTime, timeConvert } from '../../../utils/utils';
import './deviceLogs.css';
import DisplaySummary from '../../../components/common/display-summary';

export const options = [
  { id: 1, title: 'last 1 hour', value: 60 * 60 },
  { id: 2, title: 'last 6 hours', value: 6 * 60 * 60 },
  { id: 3, title: 'last 24 hours', value: 24 * 60 * 60 },
  { id: 4, title: 'last 7 days', value: 7 * 24 * 60 * 60 },
  { id: 5, title: 'last 15 days', value: 15 * 24 * 60 * 60 },
  { id: 6, title: 'last 30 days', value: 30 * 24 * 60 * 60 },
  { id: 7, title: 'last 6 month', value: 180 * 24 * 60 * 60 },
  { id: 8, title: 'last 1 year', value: 365 * 24 * 60 * 60 },
  { id: 9, title: 'all', value: 100 * 365 * 24 * 60 * 60 },
  { id: 10, title: 'Choose Date', value: 'custom-input' },
];

export default function ActiveDeviceLogs() {
  const {
    user: {
      data: { id, user_type, company },
    },
  } = useSelector((state) => state.auth) || {};

  const param = useParams();
  const [filterObj, updateFilterObj] = useState({});
  const [queryObj, updateQueryObject] = useState({
    page: 0,
    per_page: 100,
    pg_number: param.pg_number,
    start_time: '',
    end_time: '',
  });
  const callApi = useCallback(
    (param) => {
      if (user_type === SUPER_ADMIN) {
        return getActiveDeviceLogs({ ...param });
      } else if (user_type === CUSTOMER_ADMIN) {
        return getActiveDeviceLogs({ ...param, company_id: company.id });
      } else {
        return getActiveDeviceLogs({ ...param, company_id: company.id, user_id: id });
      }
    },
    [user_type]
  );
  const [filterModal, setFilterModal] = useState(false);
  const { isLoading, isError, list: data = [] } = useSelector((state) => state.deviceLogs);

  useEffect(() => {
    if (filterObj.value === 'custom-input') {
      setFilterModal(true);
      updateFilterObj({});
    } else if (filterObj.value) {
      const endTime = timeConvert().second;
      const startTime = endTime - filterObj.value;
      updateQueryObject((state) => ({ ...state, start_time: startTime, end_time: endTime }));
    }
  }, [filterObj]);

  useEffect(() => {
    callApi(queryObj);
    const event = setInterval(() => {
      callApi(queryObj);
    }, refreshTime);
    return () => clearInterval(event);
  }, [queryObj]);

  const applyFilter = (dateData) => {
    setFilterModal(false);
    const endTime = timeConvert(dateData.endDate).second;
    const startTime = timeConvert(dateData.startDate).second;
    updateQueryObject((state) => ({ ...state, start_time: startTime, end_time: endTime }));
  };

  return (
    <Suspender isLoading={isLoading} isError={isError}>
      {filterModal && (
        <Modal onClose={() => setFilterModal(false)}>
          <div className='mx-auto w-max p-4 rounded-md bg-primary-200'>
            <DateRangePicker onApply={applyFilter} />
          </div>
        </Modal>
      )}
      <div className='device-logs p-4'>
        <DisplaySummary item={data.data?.[0] || {}} />

        <div className='mb-3 mt-10 device-info max-w-lg text-start'>
          <div className='border border-primary-100 dark:border-primary-600 p-4'>
            <h4>
              PG number: <span>{param.pg_number || ''}</span>
            </h4>
            <h4>
              Device Id: <span>{param.device_id || ''}</span>
            </h4>
          </div>
        </div>
        <div className='mb-3 flex items-center justify-between'>
          <h2> Device Logs</h2>
          <div className='w-max '>
            <SelectFromOption
              headerClass='headerStyle bg-primary-100 rounded-sm dark:bg-primary-700 py-2 w-[150px]'
              options={options}
              value={filterObj}
              displayKey='title'
              onClick={(item) => updateFilterObj(item)}
              placeholder='Filter'
            />
          </div>
        </div>
        <div className='h-[530px] overflow-y-auto'>
          <table className='w-full whitespace-nowrap text-center text-primary-dark '>
            <thead className=' h-12'>
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Code</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Voltage</th>
                <th>Current</th>
                <th>Energy meter (kWh)</th>
                <th>Energy Consumed (kWh)</th>

                <th>Vibration (x,y,z)</th>
                <th>Frequency</th>
                <th>Power Factor</th>
                <th>Power</th>
                <th>KVA</th>
                <th>Limit Switch</th>

                <th>Temperature</th>
                <th>Battery Level</th>
                <th>Message</th>
                <th>Duration</th>
                <th>Network Strength</th>

                <th>Offline</th>
              </tr>
            </thead>
            <tbody>
              {data.data?.map((item) => (
                <tr key={item.id}>
                  <td className='px-4'>
                    <pre>{parseDateTime(item.time).date || '-'}</pre>
                  </td>
                  <td className='px-4'>
                    <pre>{parseDateTime(item.time).time || '-'}</pre>
                  </td>
                  <td>{item?.code || '-'}</td>
                  <td>{item.latitude || '-'}</td>
                  <td> {item.longitude || '-'}</td>
                  <td>{item.voltage || '-'}</td>
                  <td>{item.current || '-'}</td>
                  <td>{item.power_kwh || '-'}</td>
                  <td>{item.energy_consumed || '-'}</td>

                  <td>
                    ({item.vibration_x || '-'},{item.vibration_y || '-'},{item.vibration_z || '-'})
                  </td>
                  <td>{item.frequency || '-'}</td>
                  <td>{item.power_factor || '-'}</td>
                  <td>{item.power || '-'}</td>
                  <td>{item.kva || '-'}</td>
                  <td>{item.limit_switch || '-'}</td>

                  <td>{item.temperature || '-'}</td>
                  <td>{item.battery_level || '-'}</td>
                  <td>{item.message || '-'}</td>
                  <td>{item.duration || '-'}</td>
                  <td>{item.network_strength || '-'}</td>
                  <td>{item.offline ? 'offline' : 'online' || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.data?.length === 0 && <NoData />}
          <div className='w-20 mt-4'>
            <Button
              onClick={() => downloadDeviceLogs(queryObj)}
              title='Download'
              className='rounded text-xs dark:bg-primary-700 dark:text-primary-100  bg-primary-200 h-6'
            />
          </div>
        </div>

        <div className='my-4'>
          <PaginatedItems
            itemsPerPage={queryObj.per_page}
            onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })}
            totalItems={data.pagination?.total}
          />
        </div>
      </div>
    </Suspender>
  );
}

export function returnTimeStamp(timeInSeconds) {
  const d = new Date(timeInSeconds * 1000);
  const formatObj = {
    hr: d.getHours() || '0',
    min: d.getMinutes() || '0',
    sec: d.getSeconds() || '0',
  };
  const str = `${formatObj.hr > 9 ? formatObj.hr : '0' + formatObj.hr}:${formatObj.min > 9 ? formatObj.min : '0' + formatObj.min}:${
    formatObj.sec > 9 ? formatObj.sec : '0' + formatObj.sec
  }`;
  return str;
}
