import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteTowerSites, getTowerSites } from '../../../apis/apis';
import Button from '../../../components/basic/button';
import Modal, { modalCreator } from '../../../components/basic/Modals/modal with create Portal/Modal';
import ModalDelConfirmation from '../../../components/common/modal-del-confirmation';
import NoData from '../../../components/common/no-data/NoData';
import { PaginatedItems } from '../../../components/common/pagination/Paginate';
import Suspender from '../../../components/common/suspender/Suspender';
import './style.css';

export default function TowerSiteList() {
  const { isLoading, isError, list } = useSelector((state) => state.towerSites);
  const [queryObj, updateQueryObject] = useState({ page: 0, per_page: 50 });
  const [modalItem, setModalItem] = useState(null);

  const handleConfirm = () => {
    deleteTowerSites(modalItem.id);
    setModalItem(null);
  };

  useEffect(() => {
    getTowerSites(queryObj);
  }, [queryObj]);

  return (
    <Suspender isLoading={isLoading} isError={isError}>
      {modalItem && (
        <Modal onClose={() => setModalItem(null)}>
          <ModalDelConfirmation onCancel={() => setModalItem(null)} onConfirm={handleConfirm} />
        </Modal>
      )}
      <div className='tower-sites p-4'>
        <div className='table-container h-[530px] overflow-y-auto'>
          <table className='w-full text-center text-primary-dark '>
            <thead className='h-12'>
              <tr>
                <th>
                  <pre>Tower Code</pre>
                </th>
                <th>
                  <pre>Name</pre>
                </th>
                <th>
                  <pre>Latitude</pre>
                </th>
                <th>
                  <pre>Longitude</pre>
                </th>
                <th>
                  <pre>Tower Company Id</pre>
                </th>
                <th>
                  <pre>Address</pre>
                </th>
                <th>
                  <pre>Action</pre>
                </th>
              </tr>
            </thead>
            <tbody>
              {list.data?.map((item) => (
                <tr key={item.id}>
                  <td className='text-left whitespace-nowrap '>
                    <span className='w-2 h-2 mr-2 mb-[1px] rounded-full md:inline-block'></span>
                    {item.code || '-'}
                  </td>
                  <td>{item?.name || '-'}</td>
                  <td>{item.latitude || '-'}</td>
                  <td>{item.longitude || '-'}</td>
                  <td>{item.tower_company_id || '-'}</td>
                  <td>{item.address || item.region || '-'}</td>
                  <td>
                    <div className='flex items-center justify-center gap-1 h-8'>
                      {/* <h4 className='w-12 rounded-md h-full cursor-pointer border border-[#28a745] flex items-center justify-center'>
                        Edit
                      </h4> */}
                      <h4
                        onClick={() => setModalItem(item)}
                        className='w-12 rounded-md h-full cursor-pointer border border-[#dc3545] flex items-center justify-center'
                      >
                        delete
                      </h4>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {list.data?.length === 0 && <NoData />}
        </div>

        <div className='my-4'>
          <PaginatedItems
            itemsPerPage={queryObj.per_page}
            onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })}
            totalItems={list.pagination?.total}
          />
        </div>
      </div>
    </Suspender>
  );
}
