import React, { useState } from 'react';
import './LMap.css';

import { FeatureGroup, MapContainer, Marker, Popup, TileLayer, Tooltip, useMap } from 'react-leaflet';
import { Helmet } from 'react-helmet';
import { Icon } from 'leaflet';
import grayIcon from './iconGray.png';
import greenIcon from './IconGreen.png';
import L from 'leaflet';
import avg from './locationIcon.svg';
import { useRef } from 'react';
import { useEffect } from 'react';
import ActiveDeviceCard from '../cards/active-device';

const outerBounds = [
  [20.343, 90.884],
  [23.034, 90.884],
];
const markerGray = new Icon({
  iconUrl: grayIcon,
  iconSize: [50, 50],
});
const markerGreen = new Icon({
  iconUrl: greenIcon,
  iconSize: [50, 50],
});

export default function LMap({ data = [], center = [23.746625941445444, 90.41282437907465] }) {
  return (
    <>
      <MapContainer bounds={outerBounds} center={[23.034, 90.884]} zoom={7}>
        {data?.map((item, i) =>
          item.running === 1 ? (
            <Marker key={i} icon={markerGreen} position={[item.latitude, item.longitude]}>
              <Popup>
                <Popup minWidth={400}>
                  <div className='bg-primary-200'>
                    <ActiveDeviceCard item={item} />
                  </div>
                </Popup>
              </Popup>
            </Marker>
          ) : (
            <Marker icon={markerGray} key={i} position={[item.latitude, item.longitude]}>
              <Popup minWidth={400}>
                <div className='bg-primary-200'>
                  <ActiveDeviceCard item={item} />
                </div>
              </Popup>
            </Marker>
          )
        )}
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
      </MapContainer>
    </>
  );
}
