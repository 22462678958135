import axios from 'axios';
import React, { useState } from 'react';
import { postDataFromFile, postFormData } from '../../apis/apis';
import Button from '../../components/basic/button';
import { xlsxFileReaderDynamicHeader } from '../../utils/utils';
import './style.css';

const data = [
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
  {
    name: 'hasan',
  },
];

export default function QRCodeFileUpload() {
  const [formDataAsArr, updateFormDataAsArr] = useState([]);
  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/common-api/qr-code-generate`, formDataAsArr, {
        responseType: 'blob',
        headers: {
          // 'Content-Type' : 'application/pdf'
        },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'sample.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const readUploadFile = async (e) => {
    if (e.target.files) {
      const res = await xlsxFileReaderDynamicHeader(e.target.files[0]);
      updateFormDataAsArr(res);
    }
  };
  console.log(formDataAsArr);

  return (
    <div className='qr-code-file-upload p-4'>
      <div className='mb-5'>
        <input onChange={(e) => readUploadFile(e)} type='file' />
      </div>

      <div className='mb-4'>
        <Button onClick={handleSubmit} className='btnClass h-10 btn-normal' title='Upload' />
      </div>

      {/* <div className='response-data overflow-auto'>
        {trackObj?.duplicate?.length > 0 && (
          <p className='text-primary-light'>
            <span className='text-sm text-lime-500'>{trackObj.duplicate.join(',')}</span>
            <br /> {trackObj.duplicate.length} tower sites are already exist
          </p>
        )}
        {trackObj?.newCreate?.length > 0 && (
          <p className='text-primary-light'>
            {trackObj.newCreate?.length} <span className='text-sm text-lime-500'>are newly added successfully</span>{' '}
          </p>
        )}
        {trackObj?.err && <p className='text-red-500'>{trackObj.err}</p>}
      </div> */}
    </div>
  );
}
