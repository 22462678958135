import { ReactComponent as Open } from '../../../assets/icons/open.svg';
import { ReactComponent as Setting } from '../../../assets/icons/setting.svg';
import { ReactComponent as Home } from '../../../assets/icons/home.svg';
import { ReactComponent as CreateUser } from '../../../assets/icons/createUser.svg';
import { ReactComponent as CreateCompany } from '../../../assets/icons/createCompany.svg';
import { ReactComponent as TowerIcon } from '../../../assets/icons/tower.svg';
import { ReactComponent as DeviceIcon } from '../../../assets/icons/deviceIcon.svg';
import { ReactComponent as DesktopIcon } from '../../../assets/icons/desktopIcon.svg';
import { ReactComponent as SupportIcon } from '../../../assets/icons/supportIcon.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error.svg';

import { CUSTOMER_ADMIN, CUSTOMER_USER, SUPER_ADMIN } from '../../../apis/apis';
export const tabListForSuperAdmin = [
  {
    id: 1,
    label: 'menu',
    subItem: [
      {
        id: 1,
        label: 'Dashboard',
        icon: <Home />,
        subItem: [
          { id: 1, label: 'Home', link: '/' },
          { id: 2, label: 'Report Generate', link: 'dashboard/reports' },
        ],
      },
      {
        id: 2,
        label: 'User Management',
        icon: <CreateUser />,
        subItem: [
          {
            id: 1,
            label: 'Create User',
            link: 'user/create',
          },
          {
            id: 2,
            label: 'User List',
            link: 'user/list',
          },
        ],
      },
      {
        id: 3,
        label: 'Company Management',
        icon: <CreateCompany />,
        subItem: [
          {
            id: 1,
            label: 'Create Company',
            link: 'company/create',
          },
          {
            id: 2,
            label: 'Company List',
            link: 'company/list',
          },
        ],
      },
      {
        id: 4,
        label: 'Tower Management',
        icon: <TowerIcon />,
        subItem: [
          {
            id: 1,
            label: 'Create Tower Site',
            link: 'tower-site/create',
          },
          {
            id: 2,
            label: 'Tower Site List',
            link: 'tower-site/list',
          },
          {
            id: 3,
            label: 'Upload Tower Sites',
            link: 'tower-site/file-upload',
          },
        ],
      },
      {
        id: 5,
        label: 'Device Management',
        icon: <DeviceIcon />,
        subItem: [
          {
            id: 1,
            label: 'Add Device',
            link: 'device/add',
          },

          {
            id: 2,
            label: 'Device List',
            link: 'device/list',
          },
          {
            id: 3,
            label: 'Upload Devices',
            link: 'device/file-upload',
          },
        ],
      },
      {
        id: 6,
        label: 'PG Management',
        icon: <DesktopIcon />,
        subItem: [
          {
            id: 1,
            label: 'Add PG',
            link: 'pg/add',
          },

          {
            id: 2,
            label: 'PG List',
            link: 'pg/list',
          },
          {
            id: 3,
            label: 'Upload PGs',
            link: 'pg/file-upload',
          },
          {
            id: 4,
            label: 'PG Attach',
            link: 'pg/attach',
          },
        ],
      },
      {
        id: 7,
        label: 'Support',
        icon: <SupportIcon />,
        subItem: [
          {
            id: 1,
            label: 'Chat',
            link: 'chat/board',
          },

          {
            id: 2,
            label: 'Tickets',
            link: 'ticket/board',
          },
          {
            id: 3,
            label: 'Contact Us ',
            link: 'contact-us',
          },
        ],
      },
    ],
  },
  { id: 2, label: 'Open', icon: <Open /> },
  {
    id: 3,
    label: 'Setting',
    icon: <Setting />,
    subItem: [
      {
        id: 1,
        label: 'Zone Management',
        icon: <CreateCompany />,
        subItem: [
          {
            id: 1,
            label: 'Add Zone',
            link: 'zone/add',
          },
          {
            id: 2,
            label: 'Zone List',
            link: 'zone/list',
          },
        ],
      },
      {
        id: 2,
        label: 'Fault Type Management',
        icon: <ErrorIcon />,
        subItem: [
          {
            id: 1,
            label: 'Add Fault Type',
            link: 'fault-type/add',
          },
          {
            id: 2,
            label: 'Fault Type List',
            link: 'fault-type/list',
          },
        ],
      },
      {
        id: 3,
        label: 'Verification',
        icon: <TowerIcon />,
        subItem: [
          {
            id: 1,
            label: 'Tower Site Verification',
            link: 'tower/verification',
          },
          {
            id: 2,
            label: 'Close Tower Sites',
            link: 'close/tower-sites',
          },
          {
            id: 2,
            label: 'Find Duplicate Tower',
            link: 'duplicate-towers',
          },
        ],
      },
      {
        id: 4,
        label: 'QR Code Generation',
        icon: <CreateCompany />,
        subItem: [
          {
            id: 1,
            label: 'file upload',
            link: 'qr-code/file-upload',
          },
        ],
      },
    ],
  },
];

export const tabListForCustomerAdmin = [
  {
    id: 1,
    label: 'menu',
    subItem: [
      {
        id: 1,
        label: 'Dashboard',
        icon: <Home />,
        subItem: [
          { id: 1, label: 'Home', link: '/' },
          { id: 2, label: 'Report Generate', link: 'dashboard/reports' },
        ],
      },
      {
        id: 2,
        label: 'User Management',
        icon: <CreateUser />,
        subItem: [
          {
            id: 1,
            label: 'Create User',
            link: 'user/create',
          },
          {
            id: 2,
            label: 'User List',
            link: 'user/list',
          },
        ],
      },

      {
        id: 4,
        label: 'Tower Management',
        icon: <TowerIcon />,
        subItem: [
          {
            id: 1,
            label: 'Create Tower Site',
            link: 'tower-site/create',
          },
          {
            id: 2,
            label: 'Tower Site List',
            link: 'tower-site/list',
          },
          {
            id: 3,
            label: 'Upload Tower Sites',
            link: 'tower-site/file-upload',
          },
        ],
      },
      {
        id: 5,
        label: 'Device Management',
        icon: <DeviceIcon />,
        subItem: [
          {
            id: 1,
            label: 'Device List',
            link: 'device/list',
          },
        ],
      },
      {
        id: 6,
        label: 'PG Management',
        icon: <DesktopIcon />,
        subItem: [
          {
            id: 1,
            label: 'Add PG',
            link: 'pg/add',
          },

          {
            id: 2,
            label: 'PG List',
            link: 'pg/list',
          },
          {
            id: 3,
            label: 'Upload PGs',
            link: 'pg/file-upload',
          },
          {
            id: 4,
            label: 'PG Attach',
            link: 'pg/attach',
          },
        ],
      },
      {
        id: 7,
        label: 'Support',
        icon: <SupportIcon />,
        subItem: [
          {
            id: 1,
            label: 'Chat',
            link: 'chat/board',
          },

          {
            id: 2,
            label: 'Tickets',
            link: 'ticket/board',
          },
          {
            id: 3,
            label: 'Contact Us ',
            link: 'contact-us',
          },
        ],
      },
    ],
  },
  { id: 2, label: 'Open', icon: <Open /> },
  {
    id: 3,
    label: 'Setting',
    icon: <Setting />,
    subItem: [
      {
        id: 1,
        label: 'Zone Management',
        icon: <CreateCompany />,
        subItem: [
          {
            id: 1,
            label: 'Add Zone',
            link: 'zone/add',
          },
          {
            id: 2,
            label: 'Zone List',
            link: 'zone/list',
          },
        ],
      },
      {
        id: 2,
        label: 'Fault Type Management',
        icon: <ErrorIcon />,
        subItem: [
          {
            id: 1,
            label: 'Add Fault Type',
            link: 'fault-type/add',
          },
          {
            id: 2,
            label: 'Fault Type List',
            link: 'fault-type/list',
          },
        ],
      },
    ],
  },
];

export const tabListForCustomerUser = [
  {
    id: 1,
    label: 'menu',
    subItem: [
      {
        id: 1,
        label: 'Dashboard',
        icon: <Home />,
        subItem: [
          { id: 1, label: 'Home', link: '/' },
          { id: 2, label: 'Report Generate', link: 'dashboard/reports' },
        ],
      },

      {
        id: 2,
        label: 'PG Management',
        icon: <DesktopIcon />,
        subItem: [
          {
            id: 1,
            label: 'PG List',
            link: 'pg/list',
          },
          {
            id: 2,
            label: 'PG Attach',
            link: 'pg/attach',
          },
        ],
      },
      {
        id: 7,
        label: 'Support',
        icon: <SupportIcon />,
        subItem: [
          {
            id: 1,
            label: 'Chat',
            link: 'chat/board',
          },

          {
            id: 2,
            label: 'Tickets',
            link: 'ticket/board',
          },
          {
            id: 3,
            label: 'Contact Us ',
            link: 'contact-us',
          },
        ],
      },
    ],
  },
  { id: 2, label: 'Open', icon: <Open /> },
  {
    id: 3,
    label: 'Setting',
    icon: <Setting />,
    subItem: [
      {
        id: 1,
        label: 'Zone Management',
        icon: <CreateCompany />,
        subItem: [
          {
            id: 1,
            label: 'Add Zone',
            link: 'zone/add',
          },
          {
            id: 2,
            label: 'Zone List',
            link: 'zone/list',
          },
        ],
      },
      {
        id: 2,
        label: 'Fault Type Management',
        icon: <ErrorIcon />,
        subItem: [
          {
            id: 1,
            label: 'Add Fault Type',
            link: 'fault-type/add',
          },
          {
            id: 2,
            label: 'Fault Type List',
            link: 'fault-type/list',
          },
        ],
      },
    ],
  },
];

export function getSidebarData(id = CUSTOMER_USER) {
  if (id === SUPER_ADMIN) return tabListForSuperAdmin;
  else if (id === CUSTOMER_ADMIN) return tabListForCustomerAdmin;
  else return tabListForCustomerUser;
}
