import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOMER_ADMIN, getCompanies, getDuplicateTowers } from '../../apis/apis';
import Button from '../../components/basic/button';
import Input from '../../components/basic/inputs/Input/Input';
import SelectFromOption from '../../components/basic/SelectedInput/SelectedInput';
import { makeSubmittableData } from '../../components/common/forms/makeSubmittableData';
import { validation } from '../../components/common/forms/validator';
import { formatDistance } from '../../utils/utils';
import './style.css';

export default function DuplicateTowers() {
  const {
    user: {
      data: { id, user_type, company },
    },
  } = useSelector((state) => state.auth) || {};

  const [duplicateTowers, updateDuplicateTowers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    const res = await getDuplicateTowers();
    setIsLoading(false);
    if (res.status === 'ok' || 200) {
      updateDuplicateTowers(res.data);
    } else {
      alert('Some thing is wrong');
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (isLoading) return <h2 className='text-center'>Please wait until data is loaded...</h2>;

  return (
    <div>
      <h2>Duplicate Towers:</h2>
      {duplicateTowers.length > 0 && (
        <div className='my-10'>
          <div className='table-container p-4 rounded-lg border border-[#8080801f] text-sm'>
            <div className='grid grid-cols-12 grid-flow-row gap-4'>
              {duplicateTowers?.map((it, i) => (
                <div className='col-span-12'>
                  <DuplicateTowerCard item={it} key={i} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function DuplicateTowerCard({ item }) {
  return (
    <div className='duplicate-tower-card '>
      <div className='grid grid-flow-row grid-cols-12 gap-4'>
        <div className='col-span-12 xl:col-span-4 '>
          <div className='parent p-4 w-[200px] h-[124px] border border-r-0 border-primary-600 dark:border-primary-300 relative'>
            <p>
              Code: <span>{item.code || ''}</span>
            </p>
            <p>
              Latitude: <span>{item.latitude || '-'}</span>
            </p>
            <p>
              Longitude: <span>{item.longitude || '-'}</span>
            </p>
          </div>
        </div>
        <div className='col-span-12 xl:col-span-8'>
          <h2>Possible Duplicate Towers:</h2>
          {item.duplicate?.map((it) => (
            <div className='p-2 m-2 border border-primary-600 dark:border-primary-300'>
              <div className='mb-2 flex justify-between'>
                <p>
                  Code:<span>{it.code || '-'}</span>
                </p>
                <p>
                  Distance:<span>{formatDistance(it.distance || 0)}</span>
                </p>
              </div>
              <p>
                Latitude: <span>{it.latitude || '-'}</span>
              </p>
              <p>
                Longitude: <span>{it.longitude || '-'}</span>
              </p>
              <p>
                Name:<span>{it.name || ''}</span>, Address:<span>{it.address || ''}</span>, Thana:<span>{it.thana || ''}</span>, Region:
                <span>{it.region || ''}</span>
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
