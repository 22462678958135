import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CUSTOMER_ADMIN, downloadReport, getCompanies, getPgs, getReports, SUPER_ADMIN } from '../../../apis/apis';
import Button from '../../../components/basic/button';
import Modal from '../../../components/basic/Modals/modal with create Portal/Modal';
import SelectFromOption from '../../../components/basic/SelectedInput/SelectedInput';
import DateRangePicker from '../../../components/common/date-picker/DateRangePicker';
import { makeSubmittableData } from '../../../components/common/forms/makeSubmittableData';
import { checkEmptyObject } from '../../../components/common/forms/validator';
import NoData from '../../../components/common/no-data/NoData';
import { PaginatedItems } from '../../../components/common/pagination/Paginate';
import Suspender from '../../../components/common/suspender/Suspender';
import { timeConvert, makeFormObjectFromArray, parseDateTime, getFormattedServiceHours, getFormattedTime } from '../../../utils/utils';
import './ReportsGenerate.css';

export const options = [
  { id: 1, title: 'last 1 hour', value: 60 * 60 },
  { id: 2, title: 'last 6 hours', value: 6 * 60 * 60 },
  { id: 3, title: 'last 24 hours', value: 24 * 60 * 60 },
  { id: 4, title: 'last 7 days', value: 7 * 24 * 60 * 60 },
  { id: 5, title: 'last 15 days', value: 15 * 24 * 60 * 60 },
  { id: 6, title: 'last 30 days', value: 30 * 24 * 60 * 60 },
  { id: 7, title: 'last 6 month', value: 180 * 24 * 60 * 60 },
  { id: 8, title: 'last 1 year', value: 365 * 24 * 60 * 60 },
  { id: 9, title: 'all', value: 100 * 365 * 24 * 60 * 60 },
  { id: 10, title: 'Choose Date', value: 'custom-input' },
];

export default function ReportGenerate() {
  const {
    user: {
      data: { id, user_type, company },
    },
  } = useSelector((state) => state.auth) || {};
  const companyList = useSelector((state) => state.companies);
  const pgList = useSelector((state) => state.pgs);
  const [selCompany, setSelCompany] = useState({});
  const [selPg, setSelPg] = useState({});

  const [filterObj, updateFilterObj] = useState({});
  const [queryObj, updateQueryObject] = useState({
    page: 0,
    per_page: 10,
    company_id: user_type !== SUPER_ADMIN ? company.id : '',
  });
  const [filterModal, setFilterModal] = useState(false);
  const { isLoading, isError, list: data = [] } = useSelector((state) => state.reports);
  const [formData, updateFormData] = useState(
    makeFormObjectFromArray([
      { fieldName: 'company_id', value: '', required: true },
      { fieldName: 'pg_number', value: '' },
      { fieldName: 'start_time', value: '' },
      { fieldName: 'end_time', value: '' },
    ])
  );

  const onSearch = () => {
    const data = makeSubmittableData(formData);
    if (data.status) {
      updateQueryObject((state) => ({ ...state, ...data.data }));
    } else {
      updateFormData(data.data);
    }
  };

  const handleDownload = () => {
    const data = makeSubmittableData(formData);
    if (data.status) {
      downloadReport(queryObj);
    } else {
      updateFormData(data.data);
    }
  };

  useEffect(() => {
    if (filterObj.value === 'custom-input') {
      setFilterModal(true);
    } else if (!checkEmptyObject(filterObj)) {
      const endTime = timeConvert().second;
      const startTime = endTime - filterObj.value;
      let copy = { ...formData };
      copy['start_time'].value = startTime;
      copy['start_time'].error = false;
      copy['end_time'].value = endTime;
      copy['end_time'].error = false;

      updateFormData({ ...copy });
    }
  }, [filterObj]);

  useEffect(() => {
    getReports(queryObj);
  }, [queryObj]);

  useEffect(() => {
    if (!checkEmptyObject(selCompany)) {
      let copy = { ...formData };
      copy['company_id'].value = selCompany.id || '';
      copy['company_id'].error = false;
      updateFormData({ ...copy });
      setSelPg({});
    }
    if (user_type === SUPER_ADMIN && selCompany.id) {
      getPgs({ page: 0, per_page: 100, company_id: selCompany.id });
    } else if (user_type === CUSTOMER_ADMIN && company.id) {
      getPgs({ page: 0, per_page: 100, company_id: company.id });
    } else {
      getPgs({ page: 0, per_page: 100, company_id: company.id, user_id: id });
    }
  }, [selCompany]);

  useEffect(() => {
    let copy = { ...formData };
    copy['pg_number'].value = selPg.pg_number || '';
    copy['pg_number'].error = false;
    updateFormData({ ...copy });
  }, [selPg]);

  const applyFilter = (dateData) => {
    const endTime = timeConvert(dateData.endDate).second;
    const startTime = timeConvert(dateData.startDate).second;
    setFilterModal(false);
    let copy = { ...formData };
    copy['start_time'].value = startTime;
    copy['start_time'].error = false;
    copy['end_time'].value = endTime;
    copy['end_time'].error = false;
    updateFormData({ ...copy });
    updateFilterObj({});
  };

  useEffect(() => {
    if (user_type === SUPER_ADMIN) {
      getCompanies();
    } else {
      let copy = { ...formData };
      copy['company_id'].value = company.id;
      copy['company_id'].error = false;
      updateFormData({ ...copy });
    }
  }, []);

  return (
    <Suspender isLoading={isLoading} isError={isError}>
      {filterModal && (
        <Modal onClose={() => setFilterModal(false)}>
          <div className='mx-auto w-max p-4 rounded-md bg-primary-200'>
            <DateRangePicker onApply={applyFilter} />
          </div>
        </Modal>
      )}
      <div className='report-generate p-4'>
        <div className='flex flex-col md:flex-row mb-5 items-baseline gap-8'>
          {user_type === SUPER_ADMIN && (
            <div className=''>
              <SelectFromOption
                onClick={(op) => setSelCompany(op)}
                value={selCompany}
                options={companyList.list || []}
                headerClass='header-class'
                placeholder='Select Company'
                fieldError={formData.company_id?.error || false}
              />
            </div>
          )}
          <div className=''>
            <SelectFromOption
              onClick={(op) => setSelPg(op)}
              value={selPg}
              options={pgList.list?.data || []}
              headerClass='header-class'
              placeholder='Select PG'
              displayKey='pg_name'
              keyValue='pg_number'
              fieldError={formData.pg_number?.error || false}
            />
          </div>
          <div className=''>
            <SelectFromOption
              headerClass='header-class py-2'
              options={options}
              value={filterObj}
              displayKey='title'
              onClick={(item) => updateFilterObj(item)}
              placeholder='Filter'
            />
          </div>
          <div>
            <Button onClick={onSearch} title='Search' className='btnClass btn-normal h-8 px-2' />
          </div>
        </div>
        <div className='h-[530px] overflow-y-auto'>
          <table className='w-full whitespace-nowrap text-center text-primary-dark '>
            <thead className='h-12'>
              <tr>
                <th>PG Number</th>
                <th>Date</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Duration</th>
                <th>Energy Consumed(kWh)</th>
                <th>Energy Reading (kWh)</th>
                <th>THiNK Device Id</th>
                <th>Code</th>
                <th>Location</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Faulty Running Time</th>
                <th>Remarks</th>
                <th>Data Type</th>
              </tr>
            </thead>
            <tbody>
              {data.data?.map((item) => (
                <tr key={item.id}>
                  <td>{item?.pg_number || '-'}</td>
                  <td className='px-4'>
                    <pre>{parseDateTime(item.start_time).date || '-'}</pre>
                  </td>
                  <td className='px-4'>
                    <pre>{parseDateTime(item.start_time).time || '-'}</pre>
                  </td>
                  <td className='px-4'>
                    <pre>{parseDateTime(item.end_time).time || '-'}</pre>
                  </td>
                  <td>{(item?.duration && getFormattedTime(item?.duration)) || '-'}</td>
                  <td>{item?.energy_consumed || '-'}</td>
                  <td>{item?.last_energy_meter || '-'}</td>
                  <td>{item?.device_id || '-'}</td>
                  <td className='px-4'>{item?.code || '-'}</td>
                  <td>
                    {item.address || ''},{item.thana || ''}
                  </td>
                  <td>{item.latitude || '-'}</td>
                  <td> {item.longitude || '-'}</td>
                  <td>{(item.faulty_time && getFormattedServiceHours(item.faulty_time)) || '-'}</td>
                  <td>{item.message || '-'}</td>
                  <td>{item.data_type || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.data?.length === 0 && <NoData />}
          <div className='w-20 mt-4 absolute '>
            <Button
              onClick={handleDownload}
              title='Download'
              className='rounded text-xs dark:bg-primary-700 dark:text-primary-100  bg-primary-200 h-6'
            />
          </div>
        </div>

        <div className='my-4'>
          <PaginatedItems
            itemsPerPage={queryObj.per_page}
            onClick={(parm) => updateQueryObject({ ...queryObj, page: parm })}
            totalItems={data.pagination?.total}
          />
        </div>
      </div>
    </Suspender>
  );
}
